import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  "@keyframes float": {
    "0%": {
      boxShadow: '0 5px 15px 0px rgba(0,0,0,0.6)',
      transform: 'translateY(0px)',
    },
    "50%": {
      boxShadow: "0 25px 15px 0px rgba(0,0,0,0.2)",
      transform: 'translateY(-5px)',
    },
    "100%": {
      boxShadow: "0 5px 15px 0px rgba(0,0,0,0.6)",
      transform: 'translateY(0px)',
    }
  },
  videoBoxWrapper: {
    borderRadius: 40,
    boxShadow: "0px 1px 33px 0px rgba(0, 0, 0, 0.17)",
    border: "none",
    width: "13.5em",
    height: "14.6em",
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
    cursor: 'pointer',
    transform: 'translatey(0px)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    }),
    "&:hover": {
     filter: "blur(1px)",
    },
  },
  backgroundVideo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: '16px 16px 0px 0px',
  },
  videoBoxInfos: {
    width: "100%",
    background:"linear-gradient(to right, #F3B438 0%, #FFC907 100%)",
    borderRadius: '0px 0px 16px 16px',
  },
  videoName: {
    fontSize: 18,
    textAlign: "center",
    color: "#fff",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 500,
    padding: theme.spacing(1),
  },
}));

function VideoBox(props) {
  const { background, text} = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={classes.videoBoxWrapper} component="div" data-aos={isMobile ? "" : "fade-up"} data-aos-delay="300">
      <Box className={classes.backgroundVideo} component="div" style={{backgroundImage : `url(${background})`}}>
      </Box>
      <Box className={classes.videoBoxInfos} component="div">
        <Typography variant="h3" className={classes.videoName}>{text ? text : 'Beleza'}</Typography>
      </Box>
    </Box>
  );
}

export default VideoBox;
