import React, { useEffect } from "react";
import { useState } from "react";
import { CircularProgress, Container, Grid, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import businessServices from "../../services/businessServices";
import PageHeader from "../../components/common/pageHeader";

const useStyles = makeStyles((theme) => ({
  homePage: {
    width: "100%",
    height: "100vh",
    padding: 0,
  },
  spacingBox: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  division: {
    width: "100%",
    background: "#7B7F87",
  },
  appIntro: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2, 4),
    },
  },
  appIntroInfosText: {
    color: "#39adc4",
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "56.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "34px",
    },
  },
  listItem: {
    paddingLeft: 0,
  },
itemText: {
    cursor: 'pointer',
    transition: 'opacity 0.3s',
    '&:hover': {
        opacity: 0.8,
    },
},
}));

function ProfessionalsExplorer() {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        const response = await businessServices.getSectorsList();
        const data = response.data;
        const dataCategories = data.map(item => ({ id: item.id, name: item.name }));

        setCategories(dataCategories);
        setLoading(false);
      } catch (error) {
        console.error("Falha ao buscar categorias:", error);
      }
    };

    fetchProfessionals();
  }, []);

  useEffect(() => { console.log(categories) }, [categories]);

  return (
   
    <Container maxWidth={false} className={classes.homePage}>
      <PageHeader is={true}></PageHeader>
      <Grid className={classes.appIntro}>
        <Typography variant="h1" className={classes.appIntroInfosText}>
          Agende com nossos profissionais ativos no Prit
        </Typography>
        {loading ? <CircularProgress color="#39adc4" style={{left: '3%', top: '20%', position: 'absolute'}}/> :
        <List>
        {categories.map(item => (
            <ListItem key={item.id} className={classes.listItem}>
                <Typography className={classes.itemText} variant="body2" color="textSecondary" onClick={() => {window.open(`profissionais/buscar?id=${item.id}`, '_self')}}>
                    Especialista de {item.name}
                </Typography>
            </ListItem>
        ))}
    </List>}
      </Grid>
    </Container>
  );
}

export default ProfessionalsExplorer;
