import { useLocation } from 'react-router-dom';
import { isEmpty, trim } from 'lodash';

export const URLGenerator = () => {
    const location = useLocation();
    const utmContent = new URLSearchParams(location.search).get("utm_content");

    const verifiedUtmContent = utmContent ? trim(utmContent) : "";
    const defaultDeepLinkValue = "7a8b50d95c3cd876d6e571b30d7789ee";

    return `https://zdoctors.onelink.me/5A2t?af_xp=referral&pid=site_click_download&deep_link_value=${verifiedUtmContent && !isEmpty(verifiedUtmContent) ? verifiedUtmContent : defaultDeepLinkValue}`;
};