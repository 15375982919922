import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckText from "../../../components/common/checkText";
import BlueButton from "../../../components/common/blueButton";
import IntroImage from "../../../assets/intro-image.png";

const useStyles = makeStyles((theme) => ({
  appIntro: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2, 4),
    },
  },
  appIntroInfoTextBox: {
    display: "flex",
    width: "35%",
    flexDirection: "column",
    gap: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      gap: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  appIntroInfosText: {
    color: "#39adc4",
    fontFamily: "Nunito",
    fontWeight: 700,
    fontSize: 42,
    lineHeight: "56.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "34px",
    },
  },
  checksBox: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1.5),
    },
  },
  appIntroImage: {
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

const IntroSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container className={classes.appIntro}>
      <Grid
        item
        className={classes.appIntroInfoTextBox}
        data-aos={isMobile ? "" : "fade-in"}
        data-aos-delay={isMobile ? "0" : "50"}
        data-aos-duration="1000"
      >
        <Typography variant="h1" className={classes.appIntroInfosText}>
          O app que vai transformar a forma que seus clientes agendam e pagam
          com você
        </Typography>
        <Grid container className={classes.checksBox}>
          <CheckText text={"Gaste menos tempo respondendo clientes no WhatsApp"} />
          <CheckText text="Reduza ausências com a cobrança antecipada" />
          <CheckText text="Envie lembretes automáticos para o seu cliente" />
        </Grid>
        <BlueButton text="Experimente agora"/>
      </Grid>
      <Grid
        item
        data-aos={isMobile ? "" : "fade-in"}
        data-aos-delay={isMobile ? "0" : "500"}
        data-aos-duration="1000"
      >
        <Box
          component="img"
          src={IntroImage}
          alt="app working on iphone"
          className={classes.appIntroImage}
        />
      </Grid>
    </Grid>
  );
};

export default IntroSection;
