import React from 'react';
import Button from '@material-ui/core/Button';
import mixpanel from 'mixpanel-browser';
import { URLGenerator } from '../../../utils/URLGenerator';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    background: "#2873E5",
    border: "none",
    color: "#FFF",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "8px 25px",
    width: "fit-content",
    minWidth: "162px",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      background: "#fff",
      outline: "1px solid #2873E5",
      color: "#2873E5",
    },
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    whiteSpace: "nowrap",
  },
});

function BlueButton(props) {
  const classes = useStyles();
  const { text, delay } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const downloadURL = URLGenerator();
  return (
    <Button
      className={classes.root}
      onClick={() => {
        mixpanel.track('Veio pelo site - Z Doctors')
      }}
      href={`${downloadURL}`}
      data-aos={isMobile ? "" : "zoom-in"}
      data-aos-duration="800"
      data-aos-delay={!delay ? "" : delay}
      data-aos-offset={10}
    >
      {text}
    </Button>
  );
}

export default React.memo(BlueButton);
