import React, { useState } from "react";
import mixpanel from 'mixpanel-browser';
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { URLGenerator } from "../../../utils/URLGenerator";
import CheckCircleOutline from '../../../assets/icons/check-circled-outline.svg';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    borderRadius: "100px",
    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.25)",
    width: "fit-content",
    padding: 2,
    background: theme.palette.common.white,
    margin: "0 auto",
    marginBottom: theme.spacing(4),
    justifyContent: "center",
    display: "flex",
    gap: 10,
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 3),
    textTransform: "none",
  },
  selected: {
    width: "100%",
    backgroundColor: "#3B80E7",
    borderRadius: "100px",
    padding: theme.spacing(1, 4),
    color: "#fff",
    fontWeight: 600,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#3B80E7",
    },
  },
  unselected: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "100px",
    color: "#9C9C9C",
    padding: theme.spacing(1, 4),
    fontSize: "16px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  planCard: {
    borderRadius: 15,
    boxShadow: "0px 0px 14px 5px rgba(0, 0, 0, 0.13)",
    padding: theme.spacing(2),
    width: "24%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  planTitle: {
    color: "#4C4C4C",
    fontWeight: 600,
    padding: theme.spacing(2),
    paddingBottom: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: 32,
  },
  price: {
    fontWeight: "bold",
    color: "#4E525",
    fontFamily: "Nunito",
    fontSize: 30,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  offerButton: {
    marginTop: theme.spacing(2),
    color: "#fff",
    backgroundColor: "#5CABC1",
    "&:hover": {
      backgroundColor: "#5CABC1",
    },
    margin: "0 auto",
    justifyContent: "center",
    display: "flex",
    borderRadius: 50,
    textTransform: "none",
    width: "100%",
    cursor: "auto",
    fontFamily: "Nunito",
    fontSize: 18,
    fontWeight: 500,
  },
  testButton: {
    marginTop: "1rem",
    color: "#fff",
    background: "#3B80E7", "&:hover": { opacity: 0.8 },
    margin: "0 auto",
    justifyContent: "center",
    display: "flex",
    textTransform: "none",
    padding: theme.spacing(1, 5),
    borderRadius: "10px",
    fontSize: "17px",
  },
  appInfosTitle: {
    fontSize: 45,
    textAlign: "center",
    color: "#39adc4",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    display: "table",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      fontSize: 35,
    },
  },
  title: {
    color: '#4E5258',
    fontWeight: 300,
    marginBottom: theme.spacing(5),
  },
  rowsGrid: {
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(8, 0),
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      gap: 35,
    },
  },
  itemList: {
    fontSize: 18,
    color: "#7B7F87",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 500,
  },
  pricePeriod: {
    fontSize: "16px",
    fontWeight: "400"
  },
  discountAd: {
    display: "inline-block",
    backgroundColor: "#3B80E7",
    fontSize: "10px",
    padding: "3px",
    color: "#fff",
    width: "4rem",
    borderRadius: "20px",
    position: "absolute",
    bottom: "2.5rem",
    left: "5rem",
  }
}));

const PlanCard = ({ planType, monthlyPrice, isAnnual, features }) => {
  const classes = useStyles();
  const annualPrice = (monthlyPrice * 12 * 0.85).toFixed(2);
  const downloadURL = URLGenerator();

  const highlightKeywords = (text) => {
    const keywords = [
      "SMS",
      "E-mail",
      "Push",
      "Whatsapp",
      "2 profissionais",
      "50 profissionais",
    ];
    const parts = text.split(new RegExp(`(${keywords.join("|")})`, "gi"));
    return parts.map((part, index) =>
      keywords.includes(part) ? <b key={index}>{part}</b> : part
    );
  };

  return (
    <Card className={classes.planCard}>
      <Typography variant="h3" component="div" className={classes.planTitle}>
        {planType.toUpperCase()}
      </Typography>
      <CardContent>
        <Typography variant="h5" component="div" className={classes.price}>
          <span className={classes.pricePeriod}>R$</span>
          <span className={classes.priceValue}>
            {isAnnual ? annualPrice : monthlyPrice}
          </span>
          <span className={classes.pricePeriod}>
            /{isAnnual ? "ano" : "mês"}
          </span>
        </Typography>
        <Button variant="contained" className={classes.offerButton}>
          Apenas R$
          {isAnnual
            ? (annualPrice / 252).toFixed(2)
            : (monthlyPrice / 30).toFixed(2)}{" "}
          por dia!
        </Button>
        <List>
          {features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <img src={CheckCircleOutline} alt="Check Circle Icon"></img>
              </ListItemIcon>
              <ListItemText
                primary={highlightKeywords(feature)}
                className={classes.itemList}
              />
            </ListItem>
          ))}
        </List>
        <Button className={classes.testButton} href={`${downloadURL
          }`} onClick={() => {
            mixpanel.track('Veio pelo site - Z Doctors');
          }}>
          Teste agora
        </Button>
      </CardContent>
    </Card>
  );
};

const PricingSection = () => {
  const classes = useStyles();
  const [isAnnual, setIsAnnual] = useState(false);
  const features = {
    basico: [
      "2 profissionais cadastrados",
      "Lembrete de confirmação para o cliente via E-mail",
      "Notificação de nova reserva via Push",
      "Agendamentos ilimitados",
      "Controle financeiro",
      "Cadastro de clientes",
      "Cobrança antecipada (opcional) com tarifas padrão por Pix e Cartão de crédito",
      "Acompanhamento exclusivo e personalizado",
    ],
    avancado: [
      "50 profissionais cadastrados",
      "Lembrete de confirmação para o cliente via SMS",
      "Notificação de nova reserva via Whatsapp",
      "Agendamentos ilimitados",
      "Controle financeiro",
      "Cadastro de clientes",
      "Cobrança antecipada (opcional) com tarifas padrão por Pix e Cartão de crédito",
      "Acompanhamento exclusivo e personalizado",
    ],
    profissional: [
      "50 profissionais cadastrados",
      "Lembrete de confirmação para o cliente via Whatsapp",
      "Notificação de nova reserva via Whatsapp",
      "Agendamentos ilimitados",
      "Controle financeiro",
      "Cadastro de clientes",
      "Cobrança antecipada (opcional) com tarifas padrão por Pix e Cartão de crédito",
      "Acompanhamento exclusivo e personalizado",
    ],
  };

  return (
    <div id="pricing">
      <Typography variant="h2" className={classes.appInfosTitle + " " + classes.title}>
        Escolha o plano ideal para você!
      </Typography>
      <ButtonGroup className={classes.buttonGroup} exclusive orientation="none">
        <Button className={isAnnual ? classes.unselected : classes.selected} onClick={() => setIsAnnual(false)}>
          Mensal
        </Button>
        <Button className={isAnnual ? classes.selected : classes.unselected} onClick={() => setIsAnnual(true)}>
          <div className={classes.discountAd}>
            15% off
          </div>
          Anual
        </Button>
      </ButtonGroup>
      <Grid className={classes.rowsGrid}>
        <PlanCard
          planType="Básico"
          monthlyPrice={29}
          isAnnual={isAnnual}
          features={features.basico}
        />
        <PlanCard
          planType="Avançado"
          monthlyPrice={49}
          isAnnual={isAnnual}
          features={features.avancado}
        />
        <PlanCard
          planType="Profissional"
          monthlyPrice={99}
          isAnnual={isAnnual}
          features={features.profissional}
        />
      </Grid>
    </div>
  );
};

export default PricingSection;
