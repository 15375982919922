import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import Home from './pages/Home';
import './App.css';
import ProfessionalsExplorer from './pages/ProfessionalsExplorer';
import ProfessionalPage from './pages/ProfessionalPage';

function App() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profissionais" element={<ProfessionalsExplorer/>} />
          <Route path="/profissionais/buscar" element={<ProfessionalPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;